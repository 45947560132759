export default {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || '/api/',
  getExecutorsEndpoint: '/v1/accounts/',
  getContractsEndpoint: '/v1/contracts/',
  getContractEndpoint: '/v1/contracts/:id/',
  getTicketsEndpoint: '/v1/tickets/',
  getTicketEndpoint: '/v1/tickets/:id/',
  editTicketEndpoint: '/v1/tickets/:id/',
  deleteTicketEndpoint: '/v1/tickets/:id/',
  createTicketEndpoint: '/v1/tickets/',
  getTicketStatusesEndpoint: '/v1/ticket-statuses/',
  getTicketActivitiesEndpoint: '/v1/ticket-logs/',
  addTicketCommentEndpoint: '/v1/ticket-logs/',
};
